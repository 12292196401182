<template>
  <div>
    <data-table
      :uri="uri"
      :uriParams="uriParams"
      :fields="fields"
      :customItems="customItems"
      :isSelectable="true"
      no-filter
    >
      <!-- client -->
      <template #cell(client)="data">
        {{ `${data.item.client.fname} ${data.item.client.lname}` }}
      </template>
      <!-- dateCreated -->
      <template #cell(dateCreated)="data">
        {{ $d(data.value).format('YYYY-MM-DD') }}
      </template>
      <!-- service_group -->
      <template #cell(serviceGroup)="data">
        {{ data.value.customName || '' }}
      </template>
      <!-- service -->
      <template #cell(service)="data">
        {{ data.value.name || '' }}
      </template>
      <!-- client -->
      <template #cell(baseRate)="data">
        {{ `${data.item.baseRate}/${data.item.baseRateType}` }}
      </template>
    </data-table>
  </div>
</template>
<script>
import DataTable from '../../components/DataTable.vue'
import { constants } from '../../shared/constants'

export default {
  name: 'SubscriptionsList',
  components: { DataTable },
  data: () => ({
    uri: constants.API_URI_FRANCHISE_SUBSCRIPTIONS,
    customItems: {},
    active: true,
    franchiseCode: null,
    client: {
      fname: null,
      lname: null,
    },
  }),
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: true },
        'franchiseCode',
        { key: 'client', label: 'Student', sortable: true },
        { key: 'dateCreated', label: 'Created', sortable: true },
        'serviceGroup',
        'service',
        'baseRate',
      ]
    },
    uriParams() {
      const params = {
        'exists[client]': true, // only return results containing client data
        'client.fname': this.client.fname,
        'client.lname': this.client.lname,
      }
      if (this.active !== null) {
        params.active = this.active
      }
      if (this.currentFranchiseCode) {
        params.franchiseCode = this.currentFranchiseCode
      } else {
        params.franchiseCode = this.franchiseCode
      }
      return params
    },
  },
}
</script>
